const YOMILURL = {
    'getVars': function () {
        let vars = {}, hash;
        if (window.location.href.indexOf('?') > -1) {
            let hashes = window.location.href.slice(window.location.href.indexOf('?') + 1).split('&');
            for (let i = 0; i < hashes.length; i++) {
                let hash = hashes[i].split("#")[0];
                hash = hash.split('=');
                vars[hash[0]] = decodeURI(hash[1]);
            }
        }
        return vars;
    },
    'setParams': function (newParams) {
        let vars = YOMILURL.getVars();
        for (let key in newParams)
            vars[key] = newParams[key];

        let parts = window.location.href.split('?');
        let baseURL = parts[0];
        let hash = '';
        let baseURLParts = baseURL.split("#");
        if (baseURLParts.length === 2) {
            hash = '#' + baseURLParts[1];
            baseURL = baseURLParts[0];
        }
        if (parts.length === 2) {
            let hashParts = parts[1].split('#');
            if (hashParts.length === 2)
                hash = '#' + hashParts[1];
        }

        if (Object.keys(vars).length)
            window.location.href = baseURL + '?' + $.param(vars) + hash;
        else
            window.location.href = baseURL + hash;
    },
    'removeParams': function (oldParams) {
        let vars = YOMILURL.getVars();
        for (let key in oldParams)
            delete vars[key];


        let parts = window.location.href.split('?');
        let baseURL = parts[0];

        if (Object.keys(vars).length)
            window.location.href = baseURL + '?' + $.param(vars);
        else
            window.location.href = baseURL;
    }
};

const YOMILToasts = {
    toasts: [],
    init: function () {
        YOMILToasts.showOneToast();
    },
    'showOneToast': function () {
        let errors = $('.toasts-error');
        if (errors.length) {
            YOMILToasts.error($(errors[0]).text());
            $(errors[0]).remove();
        }
        let notifications = $('.toasts-notification');
        if (notifications.length) {
            YOMILToasts.notification($(notifications[0]).text());
            $(notifications[0]).remove();
        }
    },
    makeNew: function (parent) {
        let a = document.createElement("div");
        a.innerHTML = `
        <div class="toast-body">Undefined.</div>`;
        a.classList.add("toast", "fade");
        let parentDiv = $(parent);
        parentDiv.append(a);
        let index = this.toasts.push(a);

        return index - 1;
    },
    'error': function (message) {
        let a = this.makeNew('body');

        let options = {
            color: "danger",
            stacking: true,
            hidden: false,
            autohide: true,
            width: "350px",
            position: "top-right",
            delay: 5000,
        };
        $(this.toasts[a]).find(".toast-body").text(message);
        let alertInstance = new mdb.Toast(this.toasts[a], options);

        alertInstance.show();
        $(this.toasts[a]).on("hidden.mdb.toast", function () {
            $(this).remove();
        });
    },
    'notification': function (message) {
        let a = this.makeNew('body');

        let options = {
            color: "success",
            stacking: true,
            hidden: false,
            autohide: true,
            width: "350px",
            position: "top-right",
            delay: 5000,
        };
        $(this.toasts[a]).find(".toast-body").text(message);
        let alertInstance = new mdb.Toast(this.toasts[a], options);

        alertInstance.show();
        $(this.toasts[a]).on("hidden.mdb.toast", function () {
            $(this).remove();
        });
    },
    'tooltip': function (message, parent) {
        let a = this.makeNew(parent);

        let options = {
            color: "warning",
            stacking: true,
            hidden: false,
            autohide: true,
            width: "auto",
            position: "top-right",
            delay: 5000,
            container: parent
        };
        $(this.toasts[a]).find(".toast-body").html(message);
        let alertInstance = new mdb.Toast(this.toasts[a], options);

        alertInstance.show();
        $(this.toasts[a]).on("hidden.mdb.toast", function () {
            $(this).remove();
        });
    }
};


global.YOMILURL = YOMILURL;
global.YOMILToasts = YOMILToasts;
