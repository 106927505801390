const $ = require('jquery');
global.$ = $;
global.jQuery = $;

require("jquery.cookie");
import * as mdb from 'mdb5/js/mdb.min';
export default {
    mdb
}
global.mdb = mdb;

require('./functions');